<script>
export default {
  name: 'commonHeader',
  props: {
    window: Object
  },
  computed: {
    logoSrc() {
      const domain = this.$route.meta.favicon;
      if (domain === '/lx_favicon.ico') {
        return require('@/assets/img/common/lexcode_logo.png');
      } else if (domain === '/eq_favicon.ico') {
        return require('@/assets/img/common/eqqui_logo.png');
      }
      return ''; // 기본값 (로고가 없을 경우)
    },
  }
}
</script>

<template>
  <div class="footer">
    <p class="developed_by">developed by</p>
    <img class="lexcode" :src="logoSrc" />
  </div>
</template>