<script>
export default {
  name: 'mojHeader',
}
</script>

<template>
  <div class="moj">
    <router-link to="/moj">
      <div class="logo_wrap">
        <img src="@/assets/img/moj/moj_logo.png" />
      </div>
    </router-link>
    <img src="@/assets/img/moj/moj_banner.png" />
  </div>
</template>