<script>
export default {
  name: 'CPRHeader',
}
</script>

<template>
  <div class="sen">
    <router-link to="/sen">
      <div class="logo_wrap">
        <img src="@/assets/img/sen/sen_logo.jpg" />
      </div>
    </router-link>
    <img src="@/assets/img/sen/banner.png" />
  </div>
</template>