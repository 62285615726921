<script>
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0
      }
    }
  },
  created() {
    window.addEventListener('resize', this.resizeHandler)
  },
  mounted() {
    this.getWindowSize()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler() {
      this.getWindowSize()
    },
    getWindowSize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  }
}

</script>
<template>
  <div>
    <common-header :window="window"></common-header>
    <router-view class="main_content" :class="{ 'mobile': window.width < 769 }" :window="window"></router-view>
    <common-footer :window="window"></common-footer>
  </div>
</template>
